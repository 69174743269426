/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faCircleInfo,
  faGear,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import Datatable from "../widget/table/Datatable";
import DonutChart from "../widget/chart/DonutChart";
import GaugeChart from "../widget/chart/GaugeChart";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  addBidCostAmount,
  getCustomJobById,
  updateStatusofUser,
} from "../service/jobs";
import { GetjobConfigByID } from "../service/jobconfigApi";
import { BarChart } from "@mui/x-charts/BarChart";
export default function JobDetails() {
  const userInfo = JSON.parse(localStorage.getItem("AUTH_USER"));
  const userRole = userInfo?.userRole;
  const { jobId } = useParams();
  const { state } = useLocation();
  const JobNameByJobDetails = state?.jobName;
  const [show, setShow] = useState(false);
  const [formValues, setFormValues] = useState({ bid: "" });
  const [selectedRows, setSelectedRows] = useState(-1);
  const [bidCost, setBidCost] = useState("");
  const [LabourHigh, setLabourHigh] = useState("");
  const [LabourMid, setLabourMid] = useState("");
  const [LabourLow, setLabourLow] = useState("");
  const handleShow = () => {
    setFormValues({ bid: bidCost });
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalCost, settotalCost] = useState("");
  const [financeFee, setfinanceFee] = useState("");
  const [commission, setcommission] = useState("");
  const [breakEvenJobCost, setbreakEvenJobCost] = useState("");

  const [barseries, setBarseries] = useState([
    {
      name: "Job Sale Price",
      data: [],
      color: "#165DFF",
    },
    {
      name: "Job With Finance Fee",
      data: [],
      color: "#14C9C9",
    },
  ]);

  const [status, setStatus] = useState("");
  const [jobConfig, setJobConfig] = useState(null);
  const fetchJobConfig = async () => {
    try {
      const config = await GetjobConfigByID(state.jobConfigId);
      console.log(config, "raj");
      setJobConfig(config);
    } catch (error) {
      console.error("Failed to fetch job configuration:", error);
    }
  };

  const [donutData, setDonutData] = useState({
    series: [],
    labels: [],
    colors: [],
  });
  useEffect(() => {
    fetchJobConfig(jobId);
  }, [jobId]);

  const handleStatusChange = async (newStatus) => {
    const result = await updateStatusofUser(state?.jobId, newStatus);
    if (result) {
      getJobDetailsById();
      console.log(result, "result");
    }
  };

  const columns = [
    {
      name: "Selected-" + selectedRows,
      cell: (row) => (
        <input
          disabled
          type="checkbox"
          checked={selectedRows === row.entryid}
          onChange={() => handleCheckboxChange(row.entryid)}
        />
      ),
    },
    {
      name: "Net Cash Flow",
      selector: (row) => row.netCashFlow,
      cell: (row) => {
        const payment = row.netCashFlow;
        return `$${payment.toLocaleString()}`;
      },

      sortable: true,
    },
    {
      name: "Net Cash Flow %",
      selector: (row) => row.netCashFlowPercent,
      cell: (row) => <span>{row.netCashFlowPercent}%</span>,
      sortable: true,
    },

    {
      name: "Job With Finance Fee",
      selector: (row) => row.jobWithFinanceFee,
      cell: (row) => {
        const payment = row.jobWithFinanceFee;
        return `$${payment.toLocaleString()}`;
      },
      sortable: true,
    },
  ];

  const handleCheckboxChange = (id) => {
    setSelectedRows(id);
    // const rowItem = document.getElementById(`row-${id - 1}`);
    if (document.querySelector(".mystyle")) {
      document.querySelector(".mystyle").classList.remove(".mystyle");
    }
  };

  useEffect(() => {
    getJobDetailsById();
    const rowItem = document.getElementById(`row-${selectedRows - 1}`);
    if (rowItem) rowItem.classList?.toggle("mystyle");
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const bidCost = formValues.bid;

    try {
      await addBidCostAmount(state?.jobId, bidCost);
      handleClose();
      getJobDetailsById();
    } catch (error) {
      console.error("Error updating bid:", error);
    }
  };

  useEffect(() => {
    getJobDetailsById();
  }, []);

  const getJobDetailsById = async () => {
    try {
      const result = await getCustomJobById(state?.jobId);

      const cashFlowData = result?.data?.cashFlow;

      setData(cashFlowData);
      // const bidCostValue = result.data.bidCost;

      // setBidCost(formatValue(bidCostValue));
      setBidCost(result?.data?.bidCost);

      setLabourHigh(result?.data?.laborHigh?.dollerperHour);
      setLabourMid(result?.data?.laborMid?.dollerperHour);
      setLabourLow(result?.data?.laborLow?.dollerperHour);
      setBarseries([
        {
          name: "Job Sale Price",
          data: cashFlowData.map((entry) => entry?.jobSalePrice || 0),
          color: "#165DFF",
        },
        {
          name: "Job With Finance Fee",
          data: cashFlowData.map((entry) => entry?.jobWithFinanceFee || 0),
          color: "#14C9C9",
        },
      ]);
      const totalCostValue = result?.data?.totalCost;
      const financeFeeValue = result?.data?.financeFeeAmount;
      const commissionValue = result?.data?.commissionAmount;
      const breakEvenCost = result?.data?.breakEvenJobCost;

      settotalCost(totalCostValue);
      setfinanceFee(financeFeeValue);
      setcommission(commissionValue);
      setbreakEvenJobCost(result?.data?.breakEvenJobCost);
      setDonutData({
        series: [breakEvenCost, financeFeeValue, commissionValue],
        labels: ["Break Even Cost:", "Finance Fee:", "Commission:"],
        colors: ["#5B93FF", "#54FB92", "#FFD66B"],
      });

      setStatus(result?.data?.status);
      setBidCost(result.data.bidCost);
      setFormValues({ bid: result.data.bidCost });
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  useEffect(() => {
    setNearByData();
  }, [data]);

  const setNearByData = async () => {
    if (data) {
      let nearById = 0,
        amount = Infinity,
        temp = 0;

      await data.forEach((e) => {
        temp = Math.abs(bidCost - e.jobSalePrice);
        if (temp < amount) {
          amount = temp;
          nearById = e.entryid;
        }
      });

      if (nearById !== 0) {
        handleCheckboxChange(nearById);
      }
    }
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.entryid === selectedRows,
      style: {
        backgroundColor: "#b2eedc !important",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  return (
    <Container fluid className="p-0 my-3  job_details">
      <div className="d-flex justify-content-between align-items-center ">
        {" "}
        <div className="d-flex align-items-center ">
          {userRole === "CompanyAdmin" ? (
            <>
              {" "}
              <div className="row">
                <div className="col-md-12">
                  <div className="main_title"> {JobNameByJobDetails}</div>
                </div>
                <div className="col-md-12 mb-3">
                  {" "}
                  <Breadcrumb className="CompanyAdmin">
                    <Breadcrumb.Item
                      onClick={() => navigate("/UserManagement")}
                    >
                      User Management
                    </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => navigate(-1)}>
                      All Jobs
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="CurrentClass">
                      Job Details
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </>
          ) : userRole === "SuperAdmin" ? (
            <>
              {" "}
              <div className="row">
                <div className="col-md-12">
                  {" "}
                  <div className="main_title"> {JobNameByJobDetails}</div>
                </div>
                <div className="col-md-12 mb-3">
                  <Breadcrumb className="superAdmin">
                    <Breadcrumb.Item onClick={() => navigate("/Dashboard")}>
                      Dashboard
                    </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => navigate(-2)}>
                      User Management
                    </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => navigate(-1)}>
                      All Jobs
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="CurrentClass">
                      Job Details
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="row">
                <div className="col-md-12">
                  <div className="main_title "> {JobNameByJobDetails}</div>
                </div>
                <div className="col-md-12 mb-2">
                  <Breadcrumb className="superAdmin">
                    <Breadcrumb.Item onClick={() => navigate("/AllJobs")}>
                      All Jobs
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="CurrentClass">
                      Job Details
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="d-flex align-items-center mb-3 ">
          <Button className="btn custom-btn border-0 me-2" onClick={handleShow}>
            <FontAwesomeIcon icon={faPencil} className="me-2" /> {`$${bidCost}`}
          </Button>
          <Dropdown>
            <Dropdown.Toggle
              variant={
                status === "Pending"
                  ? "warning"
                  : status === "Lost"
                  ? "danger"
                  : status === "Awarded"
                  ? "success"
                  : "info"
              }
              id="dropdown-basic"
            >
              {status}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                value="Awarded"
                onClick={() => handleStatusChange("Awarded", state?.jobId)}
              >
                Awarded
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                value="Pending"
                onClick={() => handleStatusChange("Pending", state?.jobId)}
              >
                Pending
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                value="Lost"
                onClick={() => handleStatusChange("Lost", state?.jobId)}
              >
                Lost
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {userRole === "SuperAdmin" ? (
            <Button variant="outline-secondary ms-2" onClick={handleShow1}>
              {" "}
              <FontAwesomeIcon icon={faGear} />
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>

      <Row className="row_one">
        <Col md={6} className="mb-3 employee_Cost_section">
          <div className="bg-white rounded p-3">
            <div className="d-flex justify-content-between mt-2 mb-4">
              <h5>Employee Cost</h5> <FontAwesomeIcon icon={faCircleInfo} />
            </div>
            <Row>
              <Col md={4} className="gaugeChart1">
                <div className="position-relative common_class">
                  <GaugeChart
                    value={`${LabourHigh}`}
                    fillColor="#FF5B5B"
                    width={200}
                    innerRadius="61%"
                    outerRadius="100%"
                    height={200}
                  />
                  <div className="amount_class">${LabourHigh}</div>
                </div>
                <div className="text-center my-3 fw-semibold ">Labor High</div>
              </Col>
              <Col md={4} className="gaugeChart2">
                <div className="position-relative common_class">
                  <GaugeChart
                    value={LabourMid}
                    fillColor="#00B074"
                    width={200}
                    innerRadius="61%"
                    outerRadius="100%"
                    height={200}
                  />
                  <div className="amount_class">${LabourMid}</div>
                </div>
                <div className="text-center my-3 fw-semibold">Labor Mid</div>
              </Col>
              <Col md={4} className="gaugeChart3">
                <div className="position-relative common_class">
                  <GaugeChart
                    value={LabourLow}
                    fillColor="#2D9CDB"
                    width={200}
                    innerRadius="61%"
                    outerRadius="100%"
                    height={200}
                  />
                  <div className="amount_class">${LabourLow}</div>
                </div>
                <div className="text-center my-3 fw-semibold">Labor Low</div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6} className="mb-3">
          <div className="bg-white rounded p-3 jb_details">
            <div className="d-flex justify-content-between mb-4">
              <h5>Job Sale Price Vs Job With Finance Fee </h5>
            </div>
            <BarChart
              series={barseries}
              height={290}
              borderRadius={2}
              xAxis={[
                {
                  data: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                  ],
                  scaleType: "band",
                  style: {
                    fontSize: 12,
                    fontWeight: "300",
                  },
                },
              ]}
              grid={{ horizontal: true }}
              margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
            />

            <div class="label_container">
              <div className="d-flex align-items-center">
                <div class="jobSalePrice"></div>
                <span>Job Sale Price</span>
              </div>
              <div className="d-flex align-items-center">
                <div class="jobWithFinanceFee"></div>
                <span>Job With Finance Fee</span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="row_two">
        <Col md={4} className="mb-3">
          <div className="bg-white rounded p-3">
            <div className="d-flex justify-content-between">
              <h5>Breakeven Job Cost</h5>{" "}
              <FontAwesomeIcon icon={faCircleInfo} />
            </div>
            <div style={{ maxWidth: "450px", margin: "auto" }}>
              <div id="chart" className="position-relative">
                <DonutChart
                  series={donutData.series}
                  labels={donutData.labels}
                  colors={donutData.colors}
                  height={350}
                />

                <div className="customCharttext">
                  ${parseFloat(totalCost).toLocaleString()}
                </div>
              </div>
              <div id="html-dist"></div>
              <div className="BreakevenJobCostText">
                <div className="chartData d-flex align-items-center">
                  <div>
                    {" "}
                    <FontAwesomeIcon icon={faCircle} /> Break Even:
                  </div>

                  <strong className="ms-auto">
                    {" "}
                    ${parseFloat(breakEvenJobCost)?.toLocaleString()}
                    {/* {"=Total Labour+ Total Sub Contractor +Total Supply  "} */}
                  </strong>
                </div>
                <div className="chartData d-flex align-items-center">
                  <div>
                    {" "}
                    <FontAwesomeIcon icon={faCircle} /> Finance Fee:{" "}
                  </div>

                  <strong className="ms-auto">
                    ${parseFloat(financeFee)?.toLocaleString()}
                  </strong>
                </div>{" "}
                <div className="chartData d-flex align-items-center">
                  <div>
                    {" "}
                    <FontAwesomeIcon icon={faCircle} /> Commission:{" "}
                  </div>

                  <strong className="ms-auto">
                    ${parseFloat(commission)?.toLocaleString()}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={8} className="mb-3 jobDetilsTab">
          <div className="bg-white rounded p-3 pb-0">
            <div className="title-container">
              <p>Job Cost</p>
            </div>
            <Datatable
              currentPage={2}
              columns={columns}
              data={data?.map((row, index) => ({
                ...row,
                className: selectedRows === row.entryid ? "mystyle" : "",
              }))}
              conditionalRowStyles={conditionalRowStyles}
            />
          </div>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose} centered size="md">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton className="border-0">
            <Modal.Title>Job Sale</Modal.Title>
          </Modal.Header>
          <Modal.Body className="py-0">
            <div className="custom_form position-relative border-0 p-0">
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Bid</Form.Label>
                    <div className="input-group common_doller_class">
                      <span className="input-group-text">$</span>
                      <Form.Control
                        type="number"
                        name="bid"
                        placeholder="Enter Price"
                        value={formValues.bid}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              variant="outline-danger"
              className="px-5"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button variant="success" type="submit" className="px-5">
              Update
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={show1}
        onHide={handleClose1}
        centered
        size="lg"
        className="jobConfigModal "
      >
        <form>
          <Modal.Header closeButton className="border-0">
            <Modal.Title>Job Configuration</Modal.Title>
          </Modal.Header>
          <Modal.Body className="py-0">
            <div className="custom_form position-relative border-0 p-0">
              <div className="row">
                {" "}
                <div className="col-md-8 ">
                  {" "}
                  <Table className="flex-2">
                    <tr>
                      <th colspan="4" class="section-title">
                        General Info
                      </th>
                    </tr>
                    <tr>
                      <td className="title_class ">Enter Sheet Name</td>
                      <td>
                        {jobConfig?.data.map((item, index) => (
                          <div key={index}>{item.jobConfigName}</div>
                        ))}
                      </td>
                      <td className="title_class ">Description</td>
                      <td>
                        {" "}
                        {jobConfig?.data.map((item, index) => (
                          <div key={index}>{item.jobConfigDescription}</div>
                        ))}
                      </td>
                    </tr>
                  </Table>
                </div>
                <div className="col-md-4">
                  {" "}
                  <Table className="flex-1">
                    <tr>
                      <th colspan="2" class="section-title">
                        Monthly Variable Cost %
                      </th>
                    </tr>
                    <tr>
                      <td className="title_class ">Variable Cost %</td>
                      <td>
                        {" "}
                        {jobConfig?.data.map((item, index) => (
                          <div key={index}>{item.monthlyVariableCost}%</div>
                        ))}
                      </td>
                    </tr>
                  </Table>
                </div>
              </div>

              <Table>
                <tr>
                  <th colspan="4" class="section-title">
                    Over Head
                  </th>
                </tr>
                <tr>
                  <td className="title_class ">Overhead Costs</td>
                  <td>
                    {jobConfig?.data.map((item, index) => (
                      <div key={index}>${item.overheadCostMonthlyHours}</div>
                    ))}
                  </td>
                  <td className="title_class "> Owner's Pay</td>
                  <td>
                    {jobConfig?.data.map((item, index) => (
                      <div key={index}>${item.ownersPayMonthlyHours}</div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td className="title_class ">Loans</td>
                  <td>
                    {jobConfig?.data.map((item, index) => (
                      <div key={index}>${item.loansMonthlyHours}</div>
                    ))}
                  </td>
                  <td className="title_class ">Other</td>
                  <td>
                    {jobConfig?.data.map((item, index) => (
                      <div key={index}>${item.others}</div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" className="title_class ">
                    Total
                  </td>
                  <td colspan="2">
                    {jobConfig?.data.map((item, index) => (
                      <div className="text-primary " key={index}>
                        ${item.total}
                      </div>
                    ))}
                  </td>
                </tr>
              </Table>

              <Table>
                <tr>
                  <th colspan="9" class="section- title">
                    Labor Costs
                  </th>
                </tr>
                <tr>
                  <td className="title_class ">High Paid</td>
                  <td>
                    {jobConfig?.data.map((item, index) => (
                      <div key={index}>
                        ${item?.laborCostHigh?.dollarPerhour}
                      </div>
                    ))}
                  </td>
                  <td>
                    {jobConfig?.data.map((item, index) => (
                      <div key={index}>{item?.laborCostHigh?.laborBurden}</div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td className="title_class ">Mid Paid</td>
                  <td>
                    {jobConfig?.data.map((item, index) => (
                      <div key={index}>
                        ${item?.laborCostMid?.dollarPerhour}
                      </div>
                    ))}
                  </td>
                  <td>
                    {jobConfig?.data.map((item, index) => (
                      <div key={index}>{item?.laborCostMid?.laborBurden}</div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td className="title_class ">Low Paid</td>
                  <td>
                    {jobConfig?.data.map((item, index) => (
                      <div key={index}>
                        ${item?.laborCostLow?.dollarPerhour}
                      </div>
                    ))}
                  </td>
                  <td>
                    {jobConfig?.data.map((item, index) => (
                      <div key={index}>{item?.laborCostLow?.laborBurden}</div>
                    ))}
                  </td>
                </tr>
              </Table>

              <Table>
                <tr>
                  <th colspan="4" class="section-title">
                    Monthly Breakeven Cost
                  </th>
                </tr>
                <tr>
                  <td className="title_class ">High Paid</td>
                  <td>
                    {jobConfig?.data.map((item, index) => (
                      <div key={index}>
                        {item?.breakEvenHigh?.averageMonthlyHours}
                      </div>
                    ))}
                  </td>
                  <td>
                    {jobConfig?.data.map((item, index) => (
                      <div key={index}>
                        {item?.breakEvenHigh?.numberOfPeople}
                      </div>
                    ))}
                  </td>{" "}
                </tr>
                <tr>
                  <td className="title_class ">Mid Paid</td>
                  <td>
                    {jobConfig?.data.map((item, index) => (
                      <div key={index}>
                        {item?.breakEvenMid?.averageMonthlyHours}
                      </div>
                    ))}
                  </td>
                  <td>
                    {jobConfig?.data.map((item, index) => (
                      <div key={index}>
                        {item?.breakEvenMid?.numberOfPeople}
                      </div>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td className="title_class ">Low Paid</td>
                  <td>
                    {jobConfig?.data.map((item, index) => (
                      <div key={index}>
                        {item?.breakEvenLow?.averageMonthlyHours}
                      </div>
                    ))}
                  </td>
                  <td>
                    {jobConfig?.data.map((item, index) => (
                      <div key={index}>
                        {item?.breakEvenLow?.numberOfPeople}
                      </div>
                    ))}
                  </td>
                </tr>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button
              variant="outline-danger"
              className="px-5"
              onClick={handleClose1}
            >
              Close
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Container>
  );
}
