import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddToCartAPI,
  LocalGlobalProductSearch,
  SearchProductsList,
} from "../service/AddCartJobs";
import {
  setOnChangeFunc,
  setOpenTypeofSupply,
  setSearchItemInput,
  setSelectCategory,
} from "../redux/feature/jobSlice";

import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { SelectPopup } from "../components/SelectPopup";
import placholder from "../assets/image/placholder.png";
export const SearchItemsPage = () => {
  const [product, setProduct] = useState([]);
  const [itemDetails, setItemDetails] = useState({
    productName: "",
    productPrice: "",
    typeofSupply: "",
  });
  const [loading, setLoading] = useState(true);

  const queryParameters = new URLSearchParams(window.location.search);
  const query = queryParameters.get("query");
  const onChangeFunc = useSelector((state) => state.jobSlice.onChangeFunc);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchItemInput = useSelector(
    (state) => state.jobSlice.searchItemInput
  );

  const handleSearch = async () => {
    if (searchItemInput.length || query.length > 2) {
      try {
        const localResponse = await LocalGlobalProductSearch(
          // userInfo?.userId || customerUsersData,
          userRole === "CompanyUser" ? userInfo.userId : customerUsersData,
          searchItemInput || query
        );

        if (localResponse.statusCode !== 404) {
          const mappedLocalResponse = localResponse?.data.map((item) => ({
            title: item.productName,
            price: item.amount,
            image: placholder,
          }));

          setProduct(mappedLocalResponse);
        } else if (localResponse.statusCode === 404) {
          const response = await SearchProductsList(query);

          const mappedThirdPartyResponse = response?.search_results?.map(
            (item) => ({
              title: item.title,
              price: item.price,
              image: item.image || placholder,
            })
          );
          setProduct(mappedThirdPartyResponse);
        }
        dispatch(setSearchItemInput(""));
        setLoading(false);
        productDetails();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      setProduct([]);
    }
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchItemInput]);

  const productDetails = useMemo(() => {
    return (
      product?.map((item, index) => ({
        id: index,
        name: item.title,
        productPrice: item?.price,
      })) || []
    );
  }, [product]);
  console.log("productDetails", productDetails);
  useEffect(() => {
    if (productDetails.length > 0) {
      const firstProduct = productDetails[0];
      setItemDetails((prevDetails) => ({
        ...prevDetails,
        productName: firstProduct.name,
        productPrice: firstProduct.productPrice,
      }));
    }
  }, [productDetails]);

  // const iscount = useSelector((state) => state.auth.value);

  const customerUsersData = useSelector(
    (state) => state.auth.customerUsersData
  );

  const openTypeofSupply = useSelector(
    (state) => state.jobSlice.openTypeofSupply
  );
  const selectCategory = useSelector((state) => state.jobSlice.selectCategory);
  const userInfo = JSON.parse(localStorage.getItem("AUTH_USER"));
  const userRole = userInfo?.userRole;
  const handleAddtoCart = async () => {
    dispatch(setOpenTypeofSupply(true));
    const cartDetails = {
      productName: itemDetails.productName,
      productPrice: itemDetails.productPrice.value || itemDetails?.productPrice,
      typeofSupply: selectCategory,
      quantity: 1,
      userId: userRole === "CompanyUser" ? userInfo.userId : customerUsersData,
    };

    if (selectCategory) {
      try {
        const response = await AddToCartAPI(cartDetails);
        toast.success(response?.message);
        dispatch(setSelectCategory(""));
        dispatch(setOpenTypeofSupply(false));
        dispatch(setOnChangeFunc(false));
        if (response?.status) {
          setTimeout(() => {
            navigate("/CreateJob");
          }, 5000);
        }
      } catch (error) {
        console.error("Error adding to cart:", error);
        toast.error("Something went wrong, please try again.");
      }
    }
  };

  useEffect(() => {
    if (onChangeFunc) {
      handleAddtoCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCategory, onChangeFunc]);

  return (
    <Container fluid className="p-0 my-3 ">
      <div className="d-flex justify-content-between align-items-center">
        <div className="main_title mb-2"> Product List</div>
        <div>
          {" "}
          <button
            className="btn btn-success mb-2"
            onClick={() => window.history.back()}
          >
            Go Back
          </button>
        </div>

        {/* <Breadcrumb>
          <Breadcrumb.Item href="/AdditionalRevenue"></Breadcrumb.Item>
        </Breadcrumb> */}
        {/* <Button className="btn custom-btn mb-3 border-0" onClick={handleShow}>
          <FontAwesomeIcon icon={faPlus} /> Add Revenue
        </Button> */}
      </div>
      <div>
        {loading ? (
          <ReactLoading
            type={"bars"}
            color="#33995e"
            height={60}
            width={60}
            className={"globe_loader"}
          />
        ) : (
          <>
            <div className=" mb-4">
              <div className="row g-3">
                {product?.length > 0 ? (
                  <>
                    {product.map((item, index) => (
                      <div className="col-md-3" key={index}>
                        <div
                          className="card  h-100"
                          style={{
                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          }}
                        >
                          <div className="img-box d-flex justify-content-center align-items-center p-5">
                            <img
                              src={item.image}
                              alt={item.title}
                              style={{
                                width: "100%",
                                height: "250px",
                                display: "block",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                          <div className="card-body">
                            <h5 className="pb-3 pt-3">
                              {item.title.slice(0, 50) + "..."}
                            </h5>

                            <div className="d-flex justify-content-between align-items-center">
                              <h5 className="pb-3 pt-3">
                                {item?.price?.raw || item.price}
                              </h5>
                              <button
                                className="btn btn-success"
                                onClick={handleAddtoCart}
                              >
                                ADD TO CART
                              </button>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <p>No products found</p>
                )}
              </div>
              {openTypeofSupply ? <SelectPopup /> : ""}
            </div>
          </>
        )}
      </div>
    </Container>
  );
};
