import React, { useEffect, useRef, useState } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import bar from "../assets/image/bar.png";
import bell from "../assets/image/bell.png";
import { Link } from "react-router-dom";
import PuserIcon from "../assets/image/PuserIcon.png";
import help from "../assets/image/helpIcon.png";
// import ChnagePasIcon from "../assets/image/ChnagePasIcon.png";
import pLogoutIcon from "../assets/image/pLogoutIcon.png";
import notification1 from "../assets/image/notify1.png";
import notification2 from "../assets/image/notify2.png";
import notification3 from "../assets/image/notify3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckDouble,
  faCircle,
  faCircleDollarToSlot,
} from "@fortawesome/free-solid-svg-icons";
import { currentUser } from "../helpers";
import { logoutApi } from "../service/authApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { baseUrlForFile } from "../helpers";
import { getProfile } from "../service/userManagement";
import { setProfile } from "../redux/feature/profileSlice";

const Header = ({ toggleHandler }) => {
  const dispatch = useDispatch();
  const [profDrp, setprofDrp] = useState(false);
  const [notify, setnotify] = useState(false);
  const notificationRef = useRef(null);
  const profileRef = useRef(null);
  const navigate = useNavigate();
  const profilehandler = () => {
    setprofDrp(!profDrp);
  };
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  let idleTimeout;

  const userInfo = currentUser();
  const role = userInfo?.userRole;

  const firstName = useSelector((state) => state.profile.firstName);
  const lastName = useSelector((state) => state.profile.lastName);

  const selectedImage = useSelector((state) => state.profile.imageUrl);
  const companyAdmin =
    userInfo?.userRole.substr(0, 7) + " " + userInfo?.userRole.substr(7);
  const superAdmin =
    userInfo?.userRole.substr(0, 5) + " " + userInfo?.userRole.substr(5);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (profileRef.current && !profileRef.current.contains(e.target)) {
        setprofDrp(false);
      }
      if (
        notificationRef.current &&
        !notificationRef.current.contains(e.target)
      ) {
        setnotify(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [profileRef, notificationRef]);

  const handleNotification = () => {
    setnotify(!notify);
  };

  const handleLogout = async (e) => {
    e.preventDefault();

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to Logout",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
    });

    if (result.isConfirmed) {
      const token = localStorage.getItem("AUTH_ACCESS_TOKEN");

      if (!token) {
        toast.error("No token found. Please log in again.");
        navigate("/login");
        return;
      }

      try {
        const resp = await logoutApi(token);
        if (resp.status) {
          setTimeout(() => {
            toast.success(resp.message);
          }, 0);

          localStorage.removeItem("AUTH_ACCESS_TOKEN");
          localStorage.removeItem("AUTH_REFRESH_TOKEN");
          localStorage.removeItem("AUTH_USER");
          navigate("/login");
        } else {
          setTimeout(() => {
            toast.success("User logged out successfully");
          }, 0);

          localStorage.clear();
          window.location.href = "/Login";
        }
      } catch (error) {
        console.error(
          "Logout error:",
          error.response ? error.response.data : error.message
        );

        localStorage.clear();
        window.location.href = "/Login";
      }
    }
  };

  useEffect(() => {
    if (userInfo?.userId) {
      getProfile(userInfo?.userId).then((data) => {
        if (data?.data) {
          dispatch(setProfile({ ...data.data, userId: userInfo?.userId }));
        }
      });
    }
  }, [dispatch, userInfo?.userId]);
  const logoutPage = () => {
    alert("Session has timed out. Please log in.");

    setIsLoggedOut(true);
    localStorage.clear();
    window.location.href = "/login";
    console.log("User logged out due to inactivity.");
  };
  useEffect(() => {
    const resetIdleTimer = () => {
      clearTimeout(idleTimeout);
      if (!isLoggedOut) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        idleTimeout = setTimeout(() => {
          logoutPage();
        }, 1000 * 60 * 60);
      }
    };

    if (!isLoggedOut) {
      window.addEventListener("mousemove", resetIdleTimer);
      window.addEventListener("keypress", resetIdleTimer);
      resetIdleTimer();
    }

    return () => {
      clearTimeout(idleTimeout);
      window.removeEventListener("mousemove", resetIdleTimer);
      window.removeEventListener("keypress", resetIdleTimer);
    };
  }, [isLoggedOut]);

  return (
    <div className="header-section">
      <Navbar bg="white" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#home">
            <div className="navbar-icon header-toggle" onClick={toggleHandler}>
              <img src={bar} alt="" className="header-bar" />
            </div>
          </Navbar.Brand>

          <div className="navbar_section d-flex justify-content-center align-items-center">
            {role === "SuperAdmin" || role === "CompanyAdmin" ? (
              <Link
                className="pricing-btn"
                to="https://excelyourrevenue.com/product/excel-your-revenue-software-mobile-app-monthly-subscription-licenses/"
              >
                <FontAwesomeIcon icon={faCircleDollarToSlot} /> Pricing
              </Link>
            ) : (
              ""
            )}
            <div
              className={` ms-3 me-2 ${
                notify
                  ? "ActiveNotification position-relative"
                  : "position-relative"
              }`}
              ref={notificationRef}
            >
              <Nav.Link onClick={handleNotification} hidden>
                <span className="navbar-icon position-relative me-2">
                  <img src={bell} alt="" />
                  <div className="notification-count">2</div>
                </span>
              </Nav.Link>
              <div className="notification-section">
                <div className="card">
                  <div className="card-header d-flex justify-content-between">
                    <div>Notifications</div>
                    <div>
                      <div className="form-check form-switch">
                        <label className="form-check-label">
                          {" "}
                          Do Not Disturb
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    {" "}
                    <div className="notification">
                      <div className="notification-content">
                        <div>
                          <img src={notification1} alt="" />
                        </div>
                        <div className="text_Area">
                          {" "}
                          <strong>Job Name</strong>
                          <p>The interface showcases a notification</p>
                        </div>
                        <div className="time">
                          <div className="">
                            {" "}
                            <FontAwesomeIcon
                              icon={faCircle}
                              className=" text-success"
                            />
                          </div>
                          <div>Now</div>
                        </div>
                      </div>
                    </div>
                    <div className="notification">
                      <div className="notification-content">
                        <div>
                          <img src={notification2} alt="" />
                        </div>
                        <div className="text_Area">
                          {" "}
                          <strong>Job Name</strong>
                          <p>The interface showcases a notification</p>
                        </div>

                        <span className="time"> 1h ago</span>
                      </div>
                    </div>
                    <div className="notification">
                      <div className="notification-content">
                        <div>
                          <img src={notification3} alt="" />
                        </div>
                        <div className="text_Area">
                          {" "}
                          <strong>Job Name</strong>
                          <p>The interface showcases a notification</p>
                        </div>
                        <span className="time"> 1.1h ago</span>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer d-flex justify-content-between px-1">
                    <Button variant="">
                      {" "}
                      <FontAwesomeIcon icon={faCheckDouble} className="me-2" />
                      Make all as read
                    </Button>{" "}
                    <Button variant="">View all notification</Button>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                profDrp
                  ? "ProfileArea position-relative dynaMCLass"
                  : "ProfileArea position-relative"
              }`}
            >
              <div
                className="profile"
                ref={profileRef}
                onClick={profilehandler}
              >
                <div className="profileImg">
                  <img
                    src={baseUrlForFile + "/" + selectedImage}
                    alt="Profile"
                    className="adminUser "
                  />
                </div>

                <div className="profile-info">
                  <div className="profile-name">
                    {firstName} {lastName}{" "}
                  </div>

                  <div className="profile-title">
                    {role === "SuperAdmin" ? superAdmin : companyAdmin}
                  </div>
                </div>
                <div className="dropdown-arrow"></div>
              </div>

              <div className="dropdown-content">
                <Link to="/UserProfile">
                  {" "}
                  <img src={PuserIcon} alt="" /> View Profile
                </Link>
                {role === "SuperAdmin" ? (
                  <Link to="/VideoList" hidden>
                    {" "}
                    <img src={help} alt="" />
                    Set up library
                  </Link>
                ) : (
                  ""
                )}

                {/* <Link>
                  {" "}
                  <img src={ChnagePasIcon} alt="" />
                  Change Password
                </Link> */}
                <Link to="" onClick={handleLogout}>
                  {" "}
                  <img src={pLogoutIcon} alt="" />
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
