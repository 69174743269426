import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import msexcel from "../../assets/image/msexcel.png";

export default function Datatable({
  columns,
  data = [],
  filterName = "title",
  conditionalRowStyles,
  currentPage,
  itemsPerPage,
  setCurrentPage,
  setItemsPerPage,
}) {
  const [showAll, setShowAll] = useState(false);
  const [searchKey, setsearchKey] = useState("");
  const [loading, setLoading] = useState(true);
  const handleViewAll = () => {
    setShowAll((prev) => !prev);
  };
  const fetchData = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [loading]);

  const tableData = useMemo(() => {
    if (showAll) {
      return data;
    }
    return data?.filter((item) =>
      searchKey?.length > 0
        ? JSON.stringify(item)?.toLowerCase().includes(searchKey?.toLowerCase())
        : item
    );
  }, [data, searchKey, showAll]);

  const ExpandedComponent = ({ data }) => (
    <pre>{JSON.stringify(data, null, 2)}</pre>
  );

  function convertArrayOfObjectsToCSV(array) {
    let result;
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Button
      onClick={(e) => onExport(e.target.value)}
      variant="outline-secondary"
      className="btn_export"
    >
      <img src={msexcel} alt="" className="me-1" />
      Export
    </Button>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(tableData)} />,
    [downloadCSV, tableData]
  );

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  
  };

  return (
    <div className="table-container">
      <div className="tableHeader">
        <div className="fieldArea d-flex">
          <div className="search-container position-relative me-2">
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => setsearchKey(e.target.value)}
            />
            <FontAwesomeIcon icon={faSearch} />
          </div>
          <div>{actionsMemo}</div>
        </div>
        <Button onClick={handleViewAll} className="viewAll">
          View All
        </Button>
      </div>
      <div className="datatable-container">
        {loading ? (
          <div className="loader-container d-flex justify-content-center align-items-center">
            <div className="me-2">
              <Spinner animation="border" variant="success" />
            </div>
            <div className="spin_load">
              <span className="d-flex">
                Loading
                <span className="dot-one"> .</span>
                <span className="dot-two"> .</span>
                <span className="dot-three"> .</span>
              </span>
            </div>
          </div>
        ) : (
          // : tableData.length === 0 ? (
          //   <div className="no-records-container">
          //     <p>There are no records to display</p>
          //   </div>
          // )
          <DataTable
            key={showAll ? "showAll" : "filtered"}
            fixedHeader
            columns={columns}
            data={tableData}
            pagination
            expandableRowsComponent={ExpandedComponent}
            defaultSortAsc={false}
            defaultSortFieldId="Date"
            actions={actionsMemo}
            paginationComponentOptions={paginationComponentOptions}
            conditionalRowStyles={conditionalRowStyles}
      
          />
        )}
      </div>
    </div>
  );
}
