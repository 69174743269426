import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setOnChangeFunc,
  setOpenTypeofSupply,
  setSelectCategory,
} from "../redux/feature/jobSlice";

export const SelectPopup = ({ openPopup, setOpenPopup }) => {
  

  const dispatch = useDispatch();
  const selectCategory = useSelector((state) => state.jobSlice.selectCategory);
  const openTypeofSupply = useSelector(
    (state) => state.jobSlice.openTypeofSupply
  );

  const handleCategoryChange = (e) => {
    const selectedValue = e.target.value;
    dispatch(setOnChangeFunc(true));
    dispatch(setSelectCategory(selectedValue));
    dispatch(setOpenTypeofSupply(false));
  };
  return (
    <>
      <Modal
        size="sm"
        show={openPopup || openTypeofSupply}
        onHide={setOpenPopup}
        centered
        className="add_toCart"
      >
        <Modal.Header >
          <Modal.Title>Select Categories</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="custom_form1 mb-4 position-relative">
            <div className="modal_option">
              {" "}
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value="Equipments"
                  checked={selectCategory === "Equipments"}
                  onChange={handleCategoryChange}
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Equipments
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="Permits"
                  checked={selectCategory === "Permits"}
                  onChange={handleCategoryChange}
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  Permits
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                  value="Parts"
                  checked={selectCategory === "Parts"}
                  onChange={handleCategoryChange}
                />
                <label class="form-check-label" for="flexRadioDefault3">
                  Parts
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault4"
                  value="Misc"
                  checked={selectCategory === "Misc"}
                  onChange={handleCategoryChange}
                />
                <label class="form-check-label" for="flexRadioDefault4">
                  Misc
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
