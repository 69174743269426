import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPencil,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import Datatable from "../widget/table/Datatable";
import { deleteJobData,  GetJobByUserId } from "../service/jobs";
import { useDispatch } from "react-redux";
import { setCustomerUsersData } from "../redux/feature/usersDataSIice";
import { setJobId } from "../redux/feature/jobSlice";
import Swal from "sweetalert2";

export default function AllJobs() {
  const userInfo = JSON.parse(localStorage.getItem("AUTH_USER"));
  const userRole = userInfo?.userRole;
  const userId = userInfo?.userId;
  console.log((userRole, "testinh"));

  // eslint-disable-next-line no-unused-vars
  const { userID } = useParams();
  const { state } = useLocation();
  const customerUserId = state?.userID;
  const dispatch = useDispatch();
  dispatch(setCustomerUsersData(customerUserId));
  const userNameByjob = state?.userName;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const handleUpdateDetails = async (data) => {
    console.log("handleUpdateDetails", data);
    // dispatch(setEditUserDeatils(true));
    navigate("/EditJob/" + data.id);
    dispatch(setJobId(data));
  };
  const columns = [
    {
      name: "Job Name",
      selector: (row) => row.jobName,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.jobDescription,
      sortable: true,
    },
    {
      name: "Crews",
      selector: (row) => row.crewRunning,
      sortable: true,
    },
    {
      name: "Commission",
      selector: (row) => row.commission,
      cell: (row) => <span>{row.commission}%</span>,
      sortable: true,
    },
    {
      name: "Finance Fee",
      selector: (row) => row.financefee,
      cell: (row) => <span>{row.financefee}%</span>,
      sortable: true,
    },

    {
      name: "Breakeven Cost",
      selector: (row) => row.breakEvenJobCost,
      cell: (row) => {
        const payment = row.breakEvenJobCost;
        return `$${payment.toLocaleString()}`;
      },
      sortable: true,
    },

    {
      name: "Bid Cost",
      selector: (row) => row.bidCost,
      cell: (row) => {
        const payment = row.bidCost;
        return `$${payment.toLocaleString()}`;
      },
      sortable: true,
    },
    {
      name: "Estimated Cash Flow",
      selector: (row) => row.netCashFlow,
      cell: (row) => <span>${row.netCashFlow}</span>,

      sortable: true,
    },

    {
      name: "Status",
      cell: (row) => (
        <Button
          variant={
            row.status === "Pending"
              ? "warning"
              : row.status === "Lost"
              ? "danger"
              : row.status === "Awarded"
              ? "success"
              : "info"
          }
        >
          {row.status}
        </Button>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="actionBtngroup ">
          <Link
            to="/JobDetails"
            state={{
              jobId: row?.id,
              jobName: row?.jobName,
              jobConfigId: row?.jobConfigId,
            }}
            className="btn custom-btn px-1 py-1 w-auto custom-width"
          >
            <FontAwesomeIcon icon={faEye} />
            Job Details
          </Link>
          {/* {userRole === "CompanyUser" ? (
            <>
              {" "}
              <Button
                className="custom_edit"
                onClick={() => handleUpdateDetails(row)}
              >
                <FontAwesomeIcon icon={faPencil} />
              </Button>
              <Button
                className=" custom_delete"
                onClick={() => handleDelete(row.id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </>
          ) : (
            ""
          )} */}
          <Button
            className="custom_edit"
            onClick={() => handleUpdateDetails(row)}
          >
            <FontAwesomeIcon icon={faPencil} />
          </Button>
          <Button
            className=" custom_delete"
            onClick={() => handleDelete(row.id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      ),
    },
  ];
  const handleDelete = async (id) => {
    try {
      const { isConfirmed } = await Swal.fire({
        title: "Are you sure?",
        text: "This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed) {
        const response = await deleteJobData(id);
        if (response) {
          await getallJobs();
          Swal.fire("Deleted!", "Excel deleted successfully!", "success");
        } else {
          Swal.fire("Error!", "Failed to delete Company Admin.", "error");
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const setNearByData = (bidCost, jobSaleData) => {
    if (jobSaleData) {
      let minDiff = Infinity;
      let netCashFlow = 0;
      jobSaleData.forEach((e) => {
        const temp = Math.abs(bidCost - e.jobSalePrice);
        if (temp < minDiff) {
          minDiff = temp;
          netCashFlow = e.netCashFlow;
        }
      });

      return netCashFlow.toLocaleString();
    } else return 0;
  };
  // Get Jobs
  useEffect(() => {
    getallJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getallJobs = async () => {
    let result;
    if (userRole === "CompanyUser") {
      // result = await getAllJobs();
      result = await GetJobByUserId(state?.userID || userId);
    } else {
      result = await GetJobByUserId(state?.userID || userId);
    }
    const formattedData = result?.data.map((job) => {
      job.netCashFlow = setNearByData(job.bidCost, job.cashFlow);
      return job;
    });
    setData(formattedData);
  };

  return (
    <Container fluid className="p-0 my-3  createJobSection">
      <div className="d-flex justify-content-between align-items-center ">
        {" "}
        <div>
          {userRole === "CompanyAdmin" ? (
            <>
              <div className="main_title"> {userNameByjob}</div>
              <Breadcrumb className="CompanyAdmin">
                <Breadcrumb.Item onClick={() => navigate(-1)}>
                  User Management
                </Breadcrumb.Item>
                <Breadcrumb.Item className="CurrentClass">
                  All Jobs
                </Breadcrumb.Item>
              </Breadcrumb>
            </>
          ) : userRole === "SuperAdmin" ? (
            <>
              <div className="main_title"> {userNameByjob}</div>
              <Breadcrumb className="superAdmin">
                <Breadcrumb.Item onClick={() => navigate("/Dashboard")}>
                  Dashboard
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => navigate(-1)}>
                  User Management
                </Breadcrumb.Item>
                <Breadcrumb.Item className="CurrentClass">
                  All Jobs
                </Breadcrumb.Item>
              </Breadcrumb>
            </>
          ) : (
            <>
              {" "}
              <div className="main_title"> All Jobs</div>
              <Breadcrumb className="superAdmin">
                <Breadcrumb.Item></Breadcrumb.Item>
              </Breadcrumb>
            </>
          )}
        </div>
        <div>
          <Link
            to="/CreateJob"
            className="btn custom-btn mb-3 border-0 btn btn-primary custom-btn border-0"
          >
            <FontAwesomeIcon icon={faPlus} /> Add Job
          </Link>
          {/* {" "}
          {userRole === "CompanyUser" ? (
            <Link
              to="/CreateJob"
              className="btn custom-btn mb-3 border-0 btn btn-primary custom-btn border-0"
            >
              <FontAwesomeIcon icon={faPlus} /> Add Job
            </Link>
          ) : (
            ""
          )} */}
        </div>
      </div>

      <Row className="mb-3">
        <Col sm={12}>
          <Card className="border-0 p-3 rounded custom_all_jobs">
            <div className="title-container">
              <p>Jobs List</p>
            </div>
            <Datatable columns={columns} data={data} filterName="jobName" />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
