import {  faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

export default function VideoList() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="custom_container mb-3 ">
      <section id="video">
        <div className="video">
          <div className="row">
            <div className="header-area  d-flex justify-content-between align-items-center pt-3">
              <div className="main_title">Library</div>

              <div className="right-head d-flex justify-content-between align-items-center">
                <div className="list-icon me-3">
                  <i className="fa-solid fa-list"></i>
                </div>
                <div className="grid-icon me-3">
                  <i className="fa-solid fa-list"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="player-area mt-2">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <div className="card video-card border-0">
                  <div className="card-vid">
                    <video width="320" height="215" controls>
                      <source
                        src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className="card-body video-body">
                    <div className="series">New Series</div>
                    <div className="date text-muted fw-semibold">
                      Added on : 08/14/2024
                    </div>
                  </div>
                  <div className="card-footer video-footer d-flex justify-content-between align-items-center border-0">
                    <div className="publis fw-semibold">
                      <button className="btn btn-dark border-0">Publish</button>
                    </div>
                    <div className="btn-area d-flex">
                     
                      <div className="oneye">
                        <FontAwesomeIcon icon={faTrash} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="card video-card border-0">
                  <div className="card-vid">
                    <video width="320" height="215" controls>
                      <source
                        src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className="card-body video-body">
                    <div className="series">New Series</div>
                    <div className="date text-muted fw-semibold">
                      Added on : 08/14/2024
                    </div>
                  </div>
                  <div className="card-footer video-footer d-flex justify-content-between align-items-center border-0">
                    <div className="publis fw-semibold">
                      <button className="btn btn-dark border-0">Publish</button>
                    </div>
                    <div className="btn-area d-flex">
                     
                      <div className="oneye">
                        <FontAwesomeIcon icon={faTrash} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="card video-card border-0">
                  <div className="card-vid">
                    <video width="320" height="215" controls>
                      <source
                        src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className="card-body video-body">
                    <div className="series">New Series</div>
                    <div className="date text-muted fw-semibold">
                      Added on : 08/14/2024
                    </div>
                  </div>
                  <div className="card-footer video-footer d-flex justify-content-between align-items-center border-0">
                    <div className="publis fw-semibold">
                      <button className="btn btn-dark border-0">Publish</button>
                    </div>
                    <div className="btn-area d-flex">
                     
                      <div className="oneye">
                        <FontAwesomeIcon icon={faTrash} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <div className="card video-card border-0">
                  <div className="card-vid">
                    <video width="320" height="215" controls>
                      <source
                        src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className="card-body video-body">
                    <div className="series">New Series</div>
                    <div className="date text-muted fw-semibold">
                      Added on : 08/14/2024
                    </div>
                  </div>
                  <div className="card-footer video-footer d-flex justify-content-between align-items-center border-0">
                    <div className="publis fw-semibold">
                      <button className="btn btn-dark border-0">Publish</button>
                    </div>
                    <div className="btn-area d-flex">
                     
                      <div className="oneye">
                        <FontAwesomeIcon icon={faTrash} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="card video-card border-0">
                  <div className="card-vid">
                    <video width="320" height="215" controls>
                      <source
                        src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className="card-body video-body">
                    <div className="series">New Series</div>
                    <div className="date text-muted fw-semibold">
                      Added on : 08/14/2024
                    </div>
                  </div>
                  <div className="card-footer video-footer d-flex justify-content-between align-items-center border-0">
                    <div className="publis fw-semibold">
                      <button className="btn btn-dark border-0">Publish</button>
                    </div>
                    <div className="btn-area d-flex">
                     
                      <div className="oneye">
                        <FontAwesomeIcon icon={faTrash} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="card video-card border-0">
                  <div className="card-vid">
                    <video width="320" height="215" controls>
                      <source
                        src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className="card-body video-body">
                    <div className="series">New Series</div>
                    <div className="date text-muted fw-semibold">
                      Added on : 08/14/2024
                    </div>
                  </div>
                  <div className="card-footer video-footer d-flex justify-content-between align-items-center border-0">
                    <div className="publis fw-semibold">
                      <button className="btn btn-dark border-0">Publish</button>
                    </div>
                    <div className="btn-area d-flex">
                     
                      <div className="oneye">
                        <FontAwesomeIcon icon={faTrash} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="card video-card border-0">
                  <div className="card-vid">
                    <video width="320" height="215" controls>
                      <source
                        src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className="card-body video-body">
                    <div className="series">New Series</div>
                    <div className="date text-muted fw-semibold">
                      Added on : 08/14/2024
                    </div>
                  </div>
                  <div className="card-footer video-footer d-flex justify-content-between align-items-center border-0">
                    <div className="publis fw-semibold">
                      <button className="btn btn-dark border-0">Publish</button>
                    </div>
                    <div className="btn-area d-flex">
                     
                      <div className="oneye">
                        <FontAwesomeIcon icon={faTrash} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="card video-card border-0">
                  <div className="card-vid">
                    <video width="320" height="215" controls>
                      <source
                        src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className="card-body video-body">
                    <div className="series">New Series</div>
                    <div className="date text-muted fw-semibold">
                      Added on : 08/14/2024
                    </div>
                  </div>
                  <div className="card-footer video-footer d-flex justify-content-between align-items-center border-0">
                    <div className="publis fw-semibold">
                      <button className="btn btn-dark border-0">Publish</button>
                    </div>
                    <div className="btn-area d-flex">
                     
                      <div className="oneye">
                        <FontAwesomeIcon icon={faTrash} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <button className="videoUploader" onClick={handleShow}>
        +
      </button>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title"
                  defaultValue="Mark"
                />
              </Form.Group>
            </Col>
            <Col md="12">
              <Form.Group>
                <Form.Label>File</Form.Label>
                <Form.Control type="file" placeholder="Title" />
              </Form.Group>
            </Col>
            <Col md="12">
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success">Upload</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
