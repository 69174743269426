import axiosInstance from "../axiosInstance";

export const SearchProductsList = async (data) => {
  try {
    const response = await axiosInstance.get(
      "https://api.asindataapi.com/request",
      {
        params: {
          api_key: "1EFDE1E61A5B458E93BE9D0599381AD5",
          type: "search",
          amazon_domain: "amazon.com",
          search_term: data,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Third Party Search Api");
  }
};
export const LocalGlobalProductSearch = async (userId, productname) => {
  try {
    const response = await axiosInstance.get(
      `/ExportImportExcel/ProductSearch?UserId=${userId}&&productname=${productname}`
    );
    console.log(response, "localGlobal");
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------Local Global Search");
  }
};
export const AddToCartAPI = async (data) => {
  try {
    const response = await axiosInstance.post(`/Cart/AddToCart`, data);
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------update Company Admin");
  }
};

export const GetCartsByUsersIdAPI = async (userId) => {
  try {
    const response = await axiosInstance.get(
      `/Cart/GetCartByUserId?id=${userId}`
    );
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------update Company Admin");
  }
};
export const DeleteProductByIdAPI = async (cartId) => {
  try {
    const response = await axiosInstance.post(
      `/Cart/DeleteCartById?id=${cartId}`
    );
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------update Company Admin");
  }
};

export const UpdateProductByIdAPI = async (data) => {
  try {
    const response = await axiosInstance.post(`/Cart/UpdateCartById`, data);
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------update Company Admin");
  }
};

// Basic Opeartion of Job
export const CreateJobAPI = async (data) => {
  try {
    const response = await axiosInstance.post(`/Job/CreateJob`, data);
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------update Company Admin");
  }
};
export const UpdateJobAPI = async (data) => {
  try {
    const response = await axiosInstance.post(`/Job/UpdateJob`, data);
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------update Company Admin");
  }
};
export const GetJobByIdAPI = async (jobId) => {
  try {
    const response = await axiosInstance.get(`/Job/GetJobById?id=${jobId}`);
    console.log("jobIdresponse", response.data);
    return response.data;
  } catch (error) {
    console.log(error, "--------------------------update Company Admin");
  }
};
