import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { getDataByExcelFileId } from "../service/excelUpload";
import { useLocation } from "react-router-dom";
import { Breadcrumb, Col, Container, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const Products = () => {
  const [selectedRow, setSelectedRow] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalPageSize, settotalPageSize] = useState();
  const [loading, setLoading] = useState(true);
  const [lengthData, SetlengthData] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRowId, setSelectedRowId] = useState(10);
  // const deferedVal = useDeferredValue(searchQuery);
  const [sortConfig, setSortConfig] = useState({
    key: "productName",
    direction: "ascending",
  });
  const { state } = useLocation();
  console.log(data, "deferedVal===");
  const excelFileId = state.fileId;
  const rowsPerPages = [10, 50, 100, 500, 1000, 1500, 2000];
  const columns = [
    {
      name: `SL No`,
      // name: `Total Products-${lengthData.toLocaleString()}`,
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
      sortable: true,
    },
    {
      name: "Product Name",
      selector: "productName",
      sortable: true,
    },
    {
      name: "Description",
      selector: "productDescription",
      sortable: true,
    },
    {
      name: "Amount",
      selector: "amount",
      cell: (row) => formatAmount(row.amount),
      sortable: true,
    },
  ];

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDataByExcelFileId(
          excelFileId,
          currentPage,
          selectedRowId
        );

        setData(response?.data?.productdata);
        settotalPageSize(response?.data?.totalPages);
        SetlengthData(response?.data.totalNumberofProducts);

        setRowsPerPage(selectedRowId);
        setLoading(false);
      } catch (error) {
        toast.error(error);
      }
    };
    fetchData();
  }, [excelFileId, currentPage, rowsPerPage, selectedRowId]);

  const totalPages = Math.ceil(lengthData / rowsPerPage);
  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleSelectRow = (event) => {
    const selectedRow = event.target.value;
    setSelectedRowId(selectedRow);
  };

  const filteredData = Array.isArray(data)
    ? data
        .filter((row) => {
          return (
            row.productName.toLowerCase().includes(searchQuery) ||
            row.productDescription.toLowerCase().includes(searchQuery) ||
            row.amount.toString().includes(searchQuery)
          );
        })
        .sort((a, b) => {
          const aValue = a[sortConfig.key];
          const bValue = b[sortConfig.key];

          if (aValue < bValue) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        })
    : [];

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };
  const handleFirst = () => {
    setCurrentPage(1);
  };
  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPageSize) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleLast = () => {
    setCurrentPage(totalPageSize);
  };
  const handleRowClick = (id) => {
    setSelectedRow(selectedRow === id ? null : id);
  };

  return (
    <Container fluid className="p-0 my-3 product_list">
      <div className="">
        <div className="main_title"> Product List</div>
        <Breadcrumb>
          <Breadcrumb.Item href="/UploadExcel"> Excel List</Breadcrumb.Item>
          <Breadcrumb.Item href="/Products" className="CurrentClass">
            {" "}
            Products List
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>{" "}
      <Row>
        <Col md={12}>
          <div className="custom_datatable border-0 p-3 rounded card custom_Complete_Datatable">
            <div className="d-flex justify-content-between mb-2">
              <div className="title-container">
                <p>Product List</p>
              </div>
              <div className="position-relative ">
                <div className="">
                  {" "}
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="position-absolute searchIcon "
                  />
                  <input
                    type="text"
                    placeholder="Search..."
                    className="me-2 ps-4 SearchInput"
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
            {loading ? (
              <div className="loader-container d-flex justify-content-center align-items-center">
                <div className="me-2">
                  <Spinner animation="border" variant="success" />
                </div>
                <div className="spin_load">
                  <span className="d-flex">
                    Loading
                    <span className="dot-one"> .</span>
                    <span className="dot-two"> .</span>
                    <span className="dot-three"> .</span>
                  </span>
                </div>
              </div>
            ) : (
              <>
                <div className="tableFixHead">
                  <table className="mb-2 w-100 custom_fun_tab">
                    <thead>
                      <tr>
                        {columns.map((column, index) => (
                          <th
                            key={index}
                            onClick={() =>
                              column.sortable && requestSort(column.selector)
                            }
                          >
                            {column.name}
                            {sortConfig.key === column.selector
                              ? sortConfig.direction === "ascending"
                                ? " ▲"
                                : " ▼"
                              : ""}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="custom_tbody">
                      {filteredData.map((row, index) => (
                        <tr key={row.id} onClick={() => handleRowClick(row.id)}>
                          <td>
                            {" "}
                            <td>{columns[0].selector(row, index)}</td>
                          </td>
                          <td>{row.productName}</td>
                          <td>{row.productDescription}</td>
                          <td>${row.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}

            <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex align-items-center gap-2 custom_pagination">
                <div className=" me-auto custom_row_page">
                  Total Products-{lengthData?.toLocaleString()}
                </div>
                <div className="custom_row_page">
                  <div className="me-2">Rows per page:</div>
                  <div>
                    <select type="text" onChange={handleSelectRow}>
                      {rowsPerPages?.map((row) => (
                        <option value={row}>{row}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="custom_total_pages ms-4">
                  {currentPage} of {totalPages}
                </div>

                <div>
                  <button onClick={handleFirst} disabled={currentPage === 1}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                      <path fill="none" d="M24 24H0V0h24v24z"></path>
                    </svg>
                  </button>

                  <button onClick={handlePrev} disabled={currentPage === 1}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                  </button>

                  <button
                    disabled={currentPage === 1}
                    className="custom_page_number"
                  >
                    {currentPage}
                  </button>

                  <button
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                  </button>
                  <button
                    onClick={handleLast}
                    disabled={currentPage === totalPages}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Products;
