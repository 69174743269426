import React, { useEffect, useRef, useState } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import DataTable from "../widget/table/Datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { getAllCompanyAdmin } from "../service/companyManagement";
import {
  deleteExcelData,
  ExportImportExcelStatus,
  getExcelFileList,
  uploadExcelFiles,
} from "../service/excelUpload";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import ReactLoading from "react-loading";

export default function UploadExcel() {
  const [filter, setFilter] = useState("All");
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [customNames, setCustomNames] = useState([]);
  const [companyName, setCompanyName] = useState(null);
  const [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Global");

  const handleShow = () => setShow(true);
  const userInfo = JSON.parse(localStorage.getItem("AUTH_USER"));
  const superAdminId = userInfo.userId;

  const fileInputRef = useRef(null);
  const [fileArray, setFileArray] = useState([]);
  const handleClose = () => {
    setShow(false);
    setFileArray([]);
    setCustomNames([]);
    setSelectedOption("Global");
  };
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setFileArray((prevFiles) => [...prevFiles, ...files]);
  };

  const columns = [
    {
      name: "Company Name",
      selector: (row) => row.companyName,
      sortable: true,
    },
    {
      name: "File Name",

      selector: (row) => {
        const baseName = row.fileName.split(".")[0];
        return `${baseName}.xlsx`;
      },
      sortable: true,
    },

    {
      name: "Last Modified Date",
      selector: (row) => row.modifiedDate.replace("T", " ,"),
      sortable: true,
    },

    {
      name: " Size",
      selector: (row) => `${row.size} kb`,

      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <Button
          variant={row.status === false ? "outline-danger" : "outline-success"}
          onClick={() => handleStatusChange(row)}
        >
          {row.status ? "Enabled" : " Disabled"}
        </Button>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="actionBtngroup">
          <Link
            to="/Products"
            className="btn custom-btn px-2 py-1 w-auto"
            state={{ fileId: row?.id, fileName: row?.fileName }}
          >
            {" "}
            Preview Products
          </Link>

          <Button variant="" onClick={() => handleDelete(row.id)}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      ),
    },
  ];

  const handleSelectCompany = (event) => {
    setSelectedCompany(event.target.value);
    console.log("selected", event.target.value);
  };

  const handleStatusChange = async (row) => {
    let statuscode = !row.status;
    localStorage.setItem(`admin-${row.id}-status`, statuscode);

    const response = await ExportImportExcelStatus(row.id, statuscode);
    if (response) {
      setData((prevData) =>
        prevData.map((admin) =>
          admin.id === row.id ? { ...admin, status: statuscode } : admin
        )
      );

      if (response.data) {
        toast.success(response.message, {
          autoClose: 3000,
        });
      } else {
        toast.error(response.error, {
          autoClose: 3000,
        });
      }
    } else {
      toast.error("Failed to update Admin status.", { autoClose: 3000 });
    }
  };
  const handleDelete = async (id) => {
    try {
      const { isConfirmed } = await Swal.fire({
        title: "Are you sure?",
        text: "This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed) {
        const response = await deleteExcelData(id);
        if (response) {
          await getExcelFileListData();
          Swal.fire("Deleted!", "Excel deleted successfully!", "success");
        } else {
          Swal.fire("Error!", "Failed to delete Company Admin.", "error");
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  useEffect(() => {
    getExcelFileListData();
  }, []);

  const getExcelFileListData = async () => {
    const result = await getExcelFileList();
    setData(result?.data);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setFileArray((prevFiles) => [...prevFiles, ...files]);
    setCustomNames((prevNames) => [
      ...prevNames,
      ...new Array(files.length).fill(""),
    ]);
  };

  const setCustomName = (e, index) => {
    const newCustomNames = [...customNames];
    newCustomNames[index] = e.target.value;
    setCustomNames(newCustomNames);
  };

  const getCompanyList = async () => {
    const response = await getAllCompanyAdmin();
    setCompanyName(response?.data);
  };
  useEffect(() => {
    getCompanyList();
  }, []);

  const handleRemove = (e, index) => {
    e.stopPropagation();
    const updatedFiles = fileArray.filter((_, i) => i !== index);
    const updatedCustomNames = customNames.filter((_, i) => i !== index);
    setFileArray(updatedFiles);
    setCustomNames(updatedCustomNames);
  };
  const handleButtonClick = (e) => {
    e.stopPropagation();

    fileInputRef.current.click();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (fileArray.length === 0) {
        toast.error("Please choose at least one file to upload!");
        return;
      }
      if (!selectedOption) {
        toast.error("Please select an option (Local or Global)!");
        return;
      }

      let formData = new FormData();

      if (selectedOption === "Local") {
        if (selectedCompany) {
          let obj = companyName.filter((e) => e.id === selectedCompany);
          let name = obj[0].companyName;

          formData.append("companyName", name);
          formData.append("CompanyId", selectedCompany);
        } else {
          toast.error("Please select a company when choosing Local option!");
          return;
        }
      } else {
        formData.append("companyName", "SuperAdmin");
        formData.append("CompanyId", superAdminId);
      }

      if (selectedOption === "Local") {
        formData.append("CheckPoint", "Local");
      } else {
        formData.append("CheckPoint", "Global");
      }

      fileArray.forEach((file, index) => {
        setLoader(true);
        formData.append("files", file);
        formData.append("userInputFileNames", customNames[index]);
      });

      const response = await uploadExcelFiles(formData);
      if (response.status) {
        setLoader(true);
        toast.success("Uploaded Successfully");
        getExcelFileListData();
        resetForm();
        handleClose();
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setTimeout(() => {
        setLoader(false);
      }, 5000);
    }
  };
  const resetForm = () => {
    setFileArray([]);
    setCustomNames([]);
    setSelectedOption("Global");
    setSelectedCompany(null);
    fileInputRef.current.value = null;
  };
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredData = data?.filter((row) => {
    if (filter === "Global") {
      return row.companyName === "SuperAdmin";
    } else if (filter === "Local") {
      return row.companyName !== "SuperAdmin";
    } else {
      return true;
    }
  });
  return (
    <Container fluid className="p-0 my-3 product_list">
      <div className="d-flex justify-content-between align-items-center">
        <div className="main_title"> Excel List</div>
        <Breadcrumb>
          <Breadcrumb.Item href="/UploadExcel"></Breadcrumb.Item>
        </Breadcrumb>
        <Button className="btn custom-btn mb-3 border-0" onClick={handleShow}>
          <FontAwesomeIcon icon={faPlus} /> Upload Excel
        </Button>
      </div>
      <Row>
        <Col sm={12}>
          {" "}
          <Row>
            <Col md={12}>
              <div className="border-0 p-3 rounded card">
                <div className="title-container">
                  <select
                    className="rounded-2 custom_select_option"
                    onChange={handleFilterChange}
                  >
                    <option value="All">ALL</option>
                    <option value="Local">Local</option>
                    <option value="Global">Global</option>
                  </select>
                </div>
                <DataTable columns={columns} data={filteredData} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal show={show} size="lg" centered onHide={handleClose}>
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Excel</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <Row>
              <Col md={12}>
                <div className="border-0 p-3 rounded card">
                  <div className="excel_modal">
                    <div className="d-flex gap-4">
                      {" "}
                      <div
                        className="drag-area flex-fill"
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                      >
                        <div>
                          <p>Drag & Drop or</p>
                          <button
                            type="button"
                            onClick={handleButtonClick}
                            className="file_area_btn"
                          >
                            Choose file to upload
                          </button>
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                            accept=".xlsx, .xls, .csv"
                            multiple
                          />
                        </div>
                      </div>
                      <div className=" flex-fill">
                        <Form.Group>
                          <Form.Label className="d-flex">
                            Choose Company Name
                          </Form.Label>
                          <select
                            className="custom_excel_class  "
                            disabled={selectedOption === "Global"}
                            onChange={handleSelectCompany}
                            required
                          >
                            {companyName?.map((data, index) => (
                              <option
                                selected={index === 0 ? "selected" : ""}
                                key={data.id}
                                value={data.id}
                              >
                                {data.companyName}
                              </option>
                            ))}
                          </select>
                        </Form.Group>

                        <div className="checkbox-container d-flex gap-3">
                          <div className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="radio1"
                              name="optradio"
                              value="Global"
                              checked={selectedOption === "Global"}
                              onChange={handleRadioChange}
                              required
                            />
                            <label
                              className="form-check-label"
                              htmlFor="radio1"
                            >
                              Global
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="radio2"
                              name="optradio"
                              value="Local"
                              checked={selectedOption === "Local"}
                              onChange={handleRadioChange}
                              required
                            />
                            <label
                              className="form-check-label"
                              htmlFor="radio2"
                            >
                              Local
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <ol>
                      {fileArray?.map((el, index) => (
                        <div className="d-flex list_name">
                          <li key={index}>
                            <div className="d-flex justify-content-between gap-2 ">
                              <div className="flex-fill">
                                {" "}
                                Selected file: {el.name}{" "}
                              </div>
                              <div className="d-flex align-items-center flex-fill">
                                <div>
                                  <input
                                    type="text"
                                    placeholder="Enter File Name"
                                    value={customNames[index]}
                                    onChange={(e) => setCustomName(e, index)}
                                  />
                                </div>
                                <button
                                  type="button"
                                  className="trash_btn mb-2"
                                  onClick={(e) => handleRemove(e, index)}
                                >
                                  <FontAwesomeIcon icon={faXmark} />
                                </button>
                              </div>
                            </div>
                          </li>
                        </div>
                      ))}
                    </ol>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="success">
              Upload
            </Button>
          </Modal.Footer>
        </form>
        {loader ? (
          <ReactLoading
            type={"bars"}
            color="#33995e"
            height={60}
            width={60}
            className={"globe_loader"}
          />
        ) : (
          ""
        )}
      </Modal>
    </Container>
  );
}
