import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateJobFlag } from "../redux/feature/jobSlice";
import {
  DeleteJobConfig,
  GetjobConfigByID,
  jobConfiguration,
  updateJobConfig,
} from "../service/jobconfigApi";
import Swal from "sweetalert2";
export default function JobConfiguration() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const { state } = location;
  const [jobConfgs, setJobConfigs] = useState({});

  // eslint-disable-next-line no-unused-vars
  const { id } = useParams();

  const initialValues = {
    sheetName: "",
    description: "",
    variableCost: "",
    overheadCosts: "",
    ownersPay: "",
    loans: "",
    other: "",
    highPaid: "",
    highPaidburden: "",
    midPaid: "",
    midPaidburden: "",
    lowPaid: "",
    lowPaidburden: "",
    breakevenHighPaid: "",
    monthlyHighPaid: "",
    breakevenMidPaid: "",
    monthlyMidPaid: "",
    breakevenLowPaid: "",
    monthlyLowPaid: "",
    id: null,
  };

  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState(initialValues);
  const {
    sheetName,
    description,
    variableCost,
    overheadCosts,
    ownersPay,
    loans,
    other,
    highPaid,
    highPaidburden,
    midPaid,
    midPaidburden,
    lowPaid,
    lowPaidburden,
    breakevenHighPaid,
    monthlyHighPaid,
    breakevenMidPaid,
    monthlyMidPaid,
    breakevenLowPaid,
    monthlyLowPaid,
  } = formValues;

  const getConfiguration = async (id) => {
    const response = await GetjobConfigByID(id);
    console.log(response, "reset");

    if (response.data) {
      setJobConfigs(response?.data[0]);
      let responseData = response.data[0];
      setFormValues({
        id: responseData.id,
        sheetName: responseData.jobConfigName,
        description: responseData.jobConfigDescription,
        variableCost: responseData.monthlyVariableCost,
        overheadCosts: responseData.overheadCostMonthlyHours,
        ownersPay: responseData.ownersPayMonthlyHours,
        loans: responseData.loansMonthlyHours,
        other: responseData.others,
        highPaid: responseData.laborCostHigh.dollarPerhour,
        highPaidburden: responseData.laborCostHigh.laborBurden,
        midPaid: responseData.laborCostMid.dollarPerhour,
        midPaidburden: responseData.laborCostMid.laborBurden,
        lowPaid: responseData.laborCostLow.dollarPerhour,
        lowPaidburden: responseData.laborCostLow.laborBurden,
        breakevenHighPaid: responseData.breakEvenHigh.averageMonthlyHours,
        monthlyHighPaid: responseData.breakEvenHigh.numberOfPeople,
        breakevenMidPaid: responseData.breakEvenMid.averageMonthlyHours,
        monthlyMidPaid: responseData.breakEvenMid.numberOfPeople,
        breakevenLowPaid: responseData.breakEvenLow.averageMonthlyHours,
        monthlyLowPaid: responseData.breakEvenLow.numberOfPeople,
      });
    }
  };

  useEffect(() => {
    if (id) {
      getConfiguration(id);
    } else {
      setFormValues(initialValues);
      setJobConfigs({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!sheetName) {
      newErrors.sheetName = "Enter sheet name";
    }
    if (!description) {
      newErrors.description = "Enter description";
    }
    if (!variableCost) {
      newErrors.variableCost = "Enter variable cost percentage";
    }
    if (!overheadCosts) {
      newErrors.overheadCosts = "Enter overhead costs";
    }
    if (!ownersPay) {
      newErrors.ownersPay = "Enter owner's pay";
    }
    if (!loans) {
      newErrors.loans = "Enter loans amount";
    }
    if (!other) {
      newErrors.other = "Enter other costs";
    }
    if (!highPaid) {
      newErrors.highPaid = "Enter high paid labor cost";
    }
    if (!highPaidburden) {
      newErrors.highPaidburden = "Enter Labor Burden";
    }
    if (!midPaid) {
      newErrors.midPaid = "Enter mid paid labor cost";
    }
    if (!midPaidburden) {
      newErrors.midPaidburden = "Enter Labor Burden";
    }
    if (!lowPaid) {
      newErrors.lowPaid = "Enter low paid labor cost";
    }
    if (!lowPaidburden) {
      newErrors.lowPaidburden = "Enter Labor Burden";
    }
    if (!breakevenHighPaid) {
      newErrors.breakevenHighPaid = "Enter breakeven high paid";
    }
    if (!monthlyHighPaid) {
      newErrors.monthlyHighPaid = "Enter Labor Burden";
    }

    if (!breakevenMidPaid) {
      newErrors.breakevenMidPaid = "Enter breakeven mid paid";
    }
    if (!monthlyMidPaid) {
      newErrors.monthlyMidPaid = "Enter Labor Burden";
    }
    if (!breakevenLowPaid) {
      newErrors.breakevenLowPaid = "Enter breakeven low paid";
    }
    if (!monthlyLowPaid) {
      newErrors.monthlyLowPaid = "Enter Labor Burden";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const HandleJobconfiguration = async () => {
    if (jobConfgs && jobConfgs.id) {
      const { isConfirmed } = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed) {
        const result = await DeleteJobConfig(jobConfgs.id);
        if (result) {
          dispatch(updateJobFlag());
          Swal.fire(
            "Deleted!",
            "Job configuration deleted successfully!",
            "success"
          );
          navigate("/JobConfiguration");
        } else {
          Swal.fire("Error!", "Failed to delete job configuration.", "error");
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (validateForm()) {
        // setFormValues(initialValues);
        const payload = {
          jobConfigName: sheetName,
          jobConfigDescription: description,
          monthlyVariableCost: variableCost,
          overheadCostMonthlyHours: overheadCosts,
          ownersPayMonthlyHours: ownersPay,
          loansMonthlyHours: loans,
          others: other,
          laborCostHigh: {
            dollarPerhour: highPaid,
            laborBurden: highPaidburden,
          },
          laborCostMid: {
            dollarPerhour: midPaid,
            laborBurden: Number(midPaidburden),
          },
          laborCostLow: {
            dollarPerhour: Number(lowPaid),
            laborBurden: lowPaidburden,
          },
          breakEvenHigh: {
            averageMonthlyHours: breakevenHighPaid,
            numberOfPeople: monthlyHighPaid,
          },
          breakEvenMid: {
            averageMonthlyHours: breakevenMidPaid,
            numberOfPeople: monthlyMidPaid,
          },
          breakEvenLow: {
            averageMonthlyHours: breakevenLowPaid,
            numberOfPeople: monthlyLowPaid,
          },
        };
        let response = null;

        if (formValues.id) {
          payload.id = formValues.id;
          response = await updateJobConfig(payload);
          toast.success("Job configuration is successfully Updated!");
        } else {
          response = await jobConfiguration(payload);
          toast.success("Job configuration is successfully Created!");
        }

        if (response) {
          dispatch(updateJobFlag());

          if (!id) {
            setFormValues(initialValues);
            setJobConfigs({});
          }
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error(
        "An error occurred while submitting the form. Please try again."
      );
    }
  };

  return (
    <Container fluid className="p-0 my-3 createJobSection">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex gap-2">
          <Breadcrumb>
            <Breadcrumb.Item>Job Configuration</Breadcrumb.Item>
            <Breadcrumb.Item className="CurrentClass">
              {jobConfgs.id
                ? jobConfgs.jobConfigName
                : "Job Configuration Form"}{" "}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="info_icon">
            <div className="tooltip">
              <FontAwesomeIcon icon={faCircleInfo} />
              <span className="tooltiptext tooltip_text">
                It is strongly suggested to up date this info either monthly or
                yearly to keep the most accurate estimates and break evens
                possible. All figures are base off a trailing 12 month 1 year.
              </span>
            </div>
          </div>
        </div>
        <div>
          {jobConfgs.id ? (
            <Button
              className="btn custom-btn1 mb-3 "
              onClick={HandleJobconfiguration}
            >
              <FontAwesomeIcon icon={faTrash} className="me-1" />
              Delete
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        {/* General Info */}
        <Row className="">
          <Col md={8}>
            <h5 className="mb-3">General Info</h5>
            <Card className="border-0 p-3 rounded mb-3 card_general_info">
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="sheetName">
                    <Form.Label>Enter Sheet Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="sheetName"
                      value={sheetName}
                      onChange={handleChange}
                      className={errors.sheetName ? "border-danger" : ""}
                      placeholder="Enter Sheet Name"
                    />
                    {errors.sheetName && (
                      <Form.Text className="text-danger">
                        {errors.sheetName}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      value={description}
                      onChange={handleChange}
                      className={errors.description ? "border-danger" : ""}
                      placeholder="Description"
                    />
                    {errors.description && (
                      <Form.Text className="text-danger">
                        {errors.description}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={4}>
            <h5 className="mb-3">Monthly Variable Cost (%)</h5>
            <Card className="border-0 p-3 rounded">
              <Form.Group className="" controlId="variableCost">
                <div className="d-flex align-items-center">
                  {" "}
                  <Form.Label>Variable Cost (%)</Form.Label>
                  <div className="info_icon">
                    <div className="tooltip">
                      <FontAwesomeIcon icon={faCircleInfo} />
                      <span className="tooltiptext ">
                        Direct job material's, subcontractors, permits or any
                        other direct job expense as a percent of yearly revenue
                        do not include direct labor cost. that will go in your
                        estimates.
                      </span>
                    </div>
                  </div>
                </div>

                <Form.Control
                  type="number"
                  name="variableCost"
                  value={variableCost}
                  onChange={handleChange}
                  className={errors.variableCost ? "border-danger" : ""}
                  placeholder="Variable Cost (%)"
                />
                {errors.variableCost && (
                  <Form.Text className="text-danger">
                    {errors.variableCost}
                  </Form.Text>
                )}
              </Form.Group>
            </Card>
          </Col>
          <Col md={2} hidden>
            <h5 className="mb-3">Revenue Amount (Yearly)</h5>
            <Card className="border-0 p-3 rounded">
              <Form.Group className="" controlId="variableCost">
                <div className="d-flex align-items-center">
                  {" "}
                  <Form.Label>Amount</Form.Label>
                  {/* <div className="info_icon">
                    <div className="tooltip">
                      <FontAwesomeIcon icon={faCircleInfo} />
                      <span className="tooltiptext ">
                        Direct job material's, subcontractors, permits or any
                        other direct job expense as a percent of yearly revenue
                        do not include direct labor cost. that will go in your
                        estimates.
                      </span>
                    </div>
                  </div> */}
                </div>

                <Form.Control
                  type="number"
                  name="revAmount"
                  value={variableCost}
                  onChange={handleChange}
                  className={errors.variableCost ? "border-danger" : ""}
                  placeholder="Revenue Amount"
                />
                {errors.variableCost && (
                  <Form.Text className="text-danger">
                    {errors.variableCost}
                  </Form.Text>
                )}
              </Form.Group>
            </Card>
          </Col>
        </Row>

        {/* Over Head */}
        <Row className="mb-3 common_doller_class">
          <Col md={12}>
            <h5 className="mb-3">Over Head</h5>
            <Card className="border-0 p-3 rounded">
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="overheadCosts">
                    <div className="d-flex align-items-center">
                      <Form.Label>Overhead Costs</Form.Label>
                      {/* <div className="info_icon">
                        <div className="tooltip">
                          <FontAwesomeIcon icon={faCircleInfo} />
                          <span className="tooltiptext ">
                            Total 1 year time frame of Expenses on your P&L
                            minus and field labor cost office labor would be
                            included.
                          </span>
                        </div>
                      </div> */}
                    </div>
                    <div className="input-group ">
                      <span className="input-group-text">$</span>
                      <Form.Control
                        type="number"
                        name="overheadCosts"
                        value={overheadCosts}
                        onChange={handleChange}
                        className={errors.overheadCosts ? "border-danger" : ""}
                        placeholder="Enter Overhead Costs"
                      />
                    </div>
                    {errors.overheadCosts && (
                      <Form.Text className="text-danger">
                        {errors.overheadCosts}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="ownersPay">
                    <div className="d-flex align-items-center">
                      {" "}
                      <Form.Label>Owner's Pay</Form.Label>
                      <div className="info_icon">
                        <div className="tooltip">
                          <FontAwesomeIcon icon={faCircleInfo} />
                          <span className="tooltiptext ">
                            All owners total yearly cash draws not including W2
                            that would be counted on overhead cost most likely
                            on balance sheet.{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="input-group">
                      <span className="input-group-text">$</span>
                      <Form.Control
                        type="number"
                        name="ownersPay"
                        value={ownersPay}
                        onChange={handleChange}
                        className={errors.ownersPay ? "border-danger" : ""}
                        placeholder="Enter Owner's Pay"
                      />
                    </div>
                    {errors.ownersPay && (
                      <Form.Text className="text-danger">
                        {errors.ownersPay}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="loans">
                    <div className="d-flex align-items-center">
                      <Form.Label>Loans</Form.Label>
                      <div className="info_icon">
                        <div className="tooltip">
                          <FontAwesomeIcon icon={faCircleInfo} />
                          <span className="tooltiptext ">
                            All company loans total of all loan payments x 12
                            not pay off amounts do not include any loans that
                            may be on overhead cost most likely on balance
                            sheet.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="input-group">
                      <span className="input-group-text">$</span>
                      <Form.Control
                        type="number"
                        name="loans"
                        value={loans}
                        onChange={handleChange}
                        className={errors.loans ? "border-danger" : ""}
                        placeholder="Enter Loans"
                      />
                    </div>
                    {errors.loans && (
                      <Form.Text className="text-danger">
                        {errors.loans}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="other">
                    <div className="d-flex align-items-center">
                      <Form.Label>Other</Form.Label>
                      <div className="info_icon">
                        <div className="tooltip">
                          <FontAwesomeIcon icon={faCircleInfo} />
                          <span className="tooltiptext ">
                            Any other cash flow items that come out of your
                            company total for 1 year cover all cash flow that is
                            not on overhead cost.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="input-group">
                      <span className="input-group-text">$</span>
                      <Form.Control
                        type="number"
                        name="other"
                        value={other}
                        onChange={handleChange}
                        className={errors.other ? "border-danger" : ""}
                        placeholder="Enter Other Costs"
                      />
                    </div>
                    {errors.other && (
                      <Form.Text className="text-danger">
                        {errors.other}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className="mb-3 labor_Costs" hidden>
          <Col md={12}>
            <h5 className="mb-3">Total </h5>
            <Card className="border-0 p-3 rounded">
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-lg-3" controlId="highPaid">
                    <div className="d-flex align-items-center">
                      <Form.Label>Total</Form.Label>
                      <div className="info_icon">
                        <div className="tooltip">
                          <FontAwesomeIcon icon={faCircleInfo} />
                          <span className="tooltiptext ">
                            Total 1 year time frame of Expenses on your P&L
                            minus and field labor cost office labor would be
                            included.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="input-group common_doller_class">
                      <span className="input-group-text">$</span>
                      <Form.Control
                        type="number"
                        name="highPaid"
                        value={highPaid}
                        onChange={handleChange}
                        className={errors.highPaid ? "border-danger" : ""}
                        placeholder="Enter $ Per Hour"
                      />
                    </div>
                    {errors.highPaid && (
                      <Form.Text className="text-danger">
                        {errors.highPaid}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {/* Labor Costs */}
        <Row className="mb-3 labor_Costs">
          <Col md={12}>
            <h5 className="mb-3">Labor Costs</h5>
            <Card className="border-0 p-3 rounded">
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-lg-3" controlId="highPaid">
                    <div className="d-flex align-items-center">
                      <Form.Label>High Paid Per Dollar</Form.Label>
                      <div className="info_icon">
                        <div className="tooltip">
                          <FontAwesomeIcon icon={faCircleInfo} />
                          <span className="tooltiptext ">
                            Total 1 year time frame of Expenses on your P&L
                            minus and field labor cost office labor would be
                            included.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="input-group common_doller_class">
                      <span className="input-group-text">$</span>
                      <Form.Control
                        type="number"
                        name="highPaid"
                        value={highPaid}
                        onChange={handleChange}
                        className={errors.highPaid ? "border-danger" : ""}
                        placeholder="Enter $ Per Hour"
                      />
                    </div>
                    {errors.highPaid && (
                      <Form.Text className="text-danger">
                        {errors.highPaid}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group
                    className="mb-lg-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <div className="d-flex align-items-center">
                      <Form.Label>High Paid Labor Burden (%)</Form.Label>
                      <div className="info_icon">
                        <div className="tooltip">
                          <FontAwesomeIcon icon={faCircleInfo} />
                          <span className="tooltiptext ">
                            If your payroll taxes and other behind the scene
                            labor cost are not in your overhead cost please
                            enter in as a percentage for high paid employees
                            this can also help figure in for inefficient labor
                            or overtime cost.
                          </span>
                        </div>
                      </div>
                    </div>
                    <Form.Control
                      type="number"
                      name="highPaidburden"
                      value={highPaidburden}
                      onChange={handleChange}
                      className={
                        errors.highPaidburden
                          ? "border-danger mb-0 mt-lg-2"
                          : "mt-lg-2 mb-0 "
                      }
                      placeholder="Enter Labor Burden"
                    />
                    {errors.highPaidburden && (
                      <Form.Text className="text-danger">
                        {errors.highPaidburden}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-lg-3" controlId="midPaid">
                    <div className="d-flex align-items-center">
                      <Form.Label>Mid Paid Per Dollar</Form.Label>
                      <div className="info_icon">
                        <div className="tooltip">
                          <FontAwesomeIcon icon={faCircleInfo} />
                          <span className="tooltiptext ">
                            Low paid field employees as an average or your this
                            will go towards your job estimates.{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="input-group common_doller_class">
                      <span className="input-group-text">$</span>
                      <Form.Control
                        type="number"
                        name="midPaid"
                        value={midPaid}
                        onChange={handleChange}
                        className={errors.midPaid ? "border-danger" : ""}
                        placeholder="Enter $ Per Hour"
                      />
                    </div>
                    {errors.midPaid && (
                      <Form.Text className="text-danger">
                        {errors.midPaid}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group
                    className="mb-lg-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <div className="d-flex align-items-center">
                      <Form.Label>Mid Paid Labor Burden (%)</Form.Label>
                      <div className="info_icon">
                        <div className="tooltip">
                          <FontAwesomeIcon icon={faCircleInfo} />
                          <span className="tooltiptext  tooltip_text1">
                            If your payroll taxes and other behind the scene
                            labor cost are not in your overhead cost please
                            enter in as a percentage for high paid employees
                            this can also help figure in for inefficient labor
                            or overtime cost.{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <Form.Control
                      type="number"
                      name="midPaidburden"
                      value={midPaidburden}
                      onChange={handleChange}
                      className={
                        errors.midPaid
                          ? "border-danger mb-0 mt-lg-2"
                          : " mb-0 mt-lg-2"
                      }
                      placeholder="Enter Labor Burden"
                    />
                    {errors.midPaidburden && (
                      <Form.Text className="text-danger">
                        {errors.midPaidburden}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group className="mb-lg-3" controlId="lowPaid">
                    <div className="d-flex align-items-center">
                      <Form.Label>Low Paid Per Dollar</Form.Label>
                      <div className="info_icon">
                        <div className="tooltip">
                          <FontAwesomeIcon icon={faCircleInfo} />
                          <span className="tooltiptext ">
                            Low paid field employees as an average or your this
                            will go towards your job estimates.{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="input-group common_doller_class">
                      <span className="input-group-text">$</span>
                      <Form.Control
                        type="number"
                        name="lowPaid"
                        value={lowPaid}
                        onChange={handleChange}
                        className={errors.lowPaid ? "border-danger" : ""}
                        placeholder="Enter $ Per Hour"
                      />
                    </div>
                    {errors.lowPaid && (
                      <Form.Text className="text-danger">
                        {errors.lowPaid}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  {" "}
                  <Form.Group
                    className="mb-lg-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <div className="d-flex align-items-center">
                      <Form.Label>Low Paid Labor Burden (%)</Form.Label>
                      <div className="info_icon">
                        <div className="tooltip">
                          <FontAwesomeIcon icon={faCircleInfo} />
                          <span className="tooltiptext ">
                            If your payroll taxes and other behind the scene
                            labor cost are not in your overhead cost please
                            enter in as a percentage for high paid
                            employees.this can also help figure in for
                            inefficient labor or overtime cost.{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <Form.Control
                      type="number"
                      name="lowPaidburden"
                      value={lowPaidburden}
                      onChange={handleChange}
                      className={
                        errors.lowPaidburden
                          ? "border-danger mb-0 mt-lg-2"
                          : "mt-lg-2 mb-0"
                      }
                      placeholder="Enter Labor Burden"
                    />
                    {errors.lowPaidburden && (
                      <Form.Text className="text-danger">
                        {errors.lowPaidburden}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        {/* Monthly Breakeven cost */}
        <Row className="mb-3 monthly_Breakeven_cost">
          <Col md={12}>
            <h5 className="mb-3">Monthly Breakeven cost </h5>
            <Card className="border-0 p-3 rounded">
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="breakevenHighPaid">
                    <div className="d-flex align-items-center">
                      <Form.Label> High Paid Hour Month</Form.Label>
                      <div className="info_icon">
                        <div className="tooltip">
                          <FontAwesomeIcon icon={faCircleInfo} />
                          <span className="tooltiptext ">
                            Amount of hours each low paid field employee works a
                            month on average this helps figure in your break
                            evens average monthly hours are typically 173 - 174.{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <Form.Control
                      type="number"
                      name="breakevenHighPaid"
                      value={breakevenHighPaid}
                      onChange={handleChange}
                      className={
                        errors.breakevenHighPaid
                          ? "border-danger mb-0 mt-lg-2"
                          : "mt-lg-2 mb-0"
                      }
                      placeholder="Enter Breakeven High Paid"
                    />
                    {errors.breakevenHighPaid && (
                      <Form.Text className="text-danger">
                        {errors.breakevenHighPaid}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <div className="d-flex align-items-center">
                      <Form.Label>High Paid People</Form.Label>
                      <div className="info_icon">
                        <div className="tooltip">
                          <FontAwesomeIcon icon={faCircleInfo} />
                          <span className="tooltiptext ">
                            Amount of High paid field employees you currently
                            have.{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <Form.Control
                      type="number"
                      name="monthlyHighPaid"
                      value={monthlyHighPaid}
                      onChange={handleChange}
                      className={
                        errors.monthlyHighPaid
                          ? "border-danger mb-0 mt-lg-2"
                          : "mt-lg-2 mb-0"
                      }
                      placeholder="Enter Labor Burden"
                    />
                    {errors.monthlyHighPaid && (
                      <Form.Text className="text-danger">
                        {errors.monthlyHighPaid}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="breakevenMidPaid">
                    <div className="d-flex align-items-center">
                      <Form.Label> Mid Paid Hour Month</Form.Label>
                      <div className="info_icon">
                        <div className="tooltip">
                          <FontAwesomeIcon icon={faCircleInfo} />
                          <span className="tooltiptext ">
                            Amount of hours each low paid field employee works a
                            month on average this helps figure in your break
                            evens average monthly hours are typically 173 - 174.{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <Form.Control
                      type="number"
                      name="breakevenMidPaid"
                      value={breakevenMidPaid}
                      onChange={handleChange}
                      className={
                        errors.breakevenMidPaid
                          ? "border-danger mb-0 mt-lg-2"
                          : "mt-lg-2 mb-0"
                      }
                      placeholder="Enter Breakeven Mid Paid"
                    />
                    {errors.breakevenMidPaid && (
                      <Form.Text className="text-danger">
                        {errors.breakevenMidPaid}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <div className="d-flex align-items-center">
                      <Form.Label>Mid Paid People</Form.Label>
                      <div className="info_icon">
                        <div className="tooltip">
                          <FontAwesomeIcon icon={faCircleInfo} />
                          <span className="tooltiptext tooltip_text1">
                            Amount of mid paid field employees you currently
                            have.{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <Form.Control
                      type="number"
                      name="monthlyMidPaid"
                      value={monthlyMidPaid}
                      onChange={handleChange}
                      className={
                        errors.monthlyMidPaid
                          ? "border-danger mb-0 mt-lg-2"
                          : "mt-lg-2 mb-0"
                      }
                      placeholder="Enter Labor Burden"
                    />
                    {errors.monthlyHighPaid && (
                      <Form.Text className="text-danger">
                        {errors.monthlyHighPaid}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="breakevenLowPaid">
                    <div className="d-flex align-items-center">
                      <Form.Label> Low Paid Hour Month</Form.Label>
                      <div className="info_icon">
                        <div className="tooltip">
                          <FontAwesomeIcon icon={faCircleInfo} />
                          <span className="tooltiptext ">
                            Amount of hours each low paid field employee works a
                            month on average this helps figure in your break
                            evens average monthly hours are typically 173 - 174.{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <Form.Control
                      type="number"
                      name="breakevenLowPaid"
                      value={breakevenLowPaid}
                      onChange={handleChange}
                      className={errors.breakevenLowPaid ? "border-danger" : ""}
                      placeholder="Enter Breakeven Low Paid"
                    />
                    {errors.breakevenLowPaid && (
                      <Form.Text className="text-danger">
                        {errors.breakevenLowPaid}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <div className="d-flex align-items-center">
                      <Form.Label>Low Paid People</Form.Label>
                      <div className="info_icon">
                        <div className="tooltip">
                          <FontAwesomeIcon icon={faCircleInfo} />
                          <span className="tooltiptext ">
                            Amount of low paid field employees you currently
                            have.{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <Form.Control
                      type="number"
                      name="monthlyLowPaid"
                      value={monthlyLowPaid}
                      onChange={handleChange}
                      className={
                        errors.monthlyLowPaid
                          ? "border-danger mt-lg-0"
                          : "mt-lg-0"
                      }
                      placeholder="Enter Labor Burden"
                    />
                    {errors.monthlyLowPaid && (
                      <Form.Text className="text-danger">
                        {errors.monthlyLowPaid}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <div className="d-flex justify-content-end mb-5">
          <Button variant="success" className="px-5 mt-2" type="submit">
            {jobConfgs.id ? "Update" : "   Save"}
          </Button>
        </div>
      </Form>
    </Container>
  );
}
