import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteProductByIdAPI,
  GetCartsByUsersIdAPI,
  UpdateProductByIdAPI,
} from "../service/AddCartJobs";
import { setAddedCartLength } from "../redux/feature/usersDataSIice";
import { toast } from "react-toastify";
import { setOpenTypeofSupply } from "../redux/feature/jobSlice";
import { SelectPopup } from "../components/SelectPopup";
import Swal from "sweetalert2";

export const AddedCartList = () => {
  const [productDetails, setProductDetails] = useState("");
  const [cartData, setCartData] = useState([]);
  const [itemDtails, setItemDtails] = useState({
    id: "",
    productName: "",
    productPrice: "",
    typeofSupply: "",
    quantity: "",
    userId: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  console.log(itemDtails, "itemDtails");
  console.log(cartData, "cartData");
  console.log(productDetails, "productDetails");

  const dispatch = useDispatch();
  const selectCategory = useSelector((state) => state.jobSlice.selectCategory);
  const customerUsersData = useSelector(
    (state) => state.auth.customerUsersData
  );

  const onChangeFunc = useSelector((state) => state.jobSlice.onChangeFunc);
  const userInfo = JSON.parse(localStorage.getItem("AUTH_USER"));
  const userRole = userInfo?.userRole;
  const handleGetCardByUserId = async () => {
    try {
      const response = await GetCartsByUsersIdAPI(
        // userInfo.userId ?? customerUsersData
        userRole === "CompanyUser" ? userInfo.userId : customerUsersData
      );
      console.error("AAAAAAAAAAAAAAAAAAAAAAAAAA", response);
      dispatch(setAddedCartLength(response?.data?.cartData?.length));
      setProductDetails(response.data);
      setCartData(response?.data?.cartData || []);
    } catch (error) {
      setCartData([]);
      console.error("Error fetching cart data", error);
    } finally {
      console.error("CCCCCCCCCCCCCCCCCCC");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleGetCardByUserId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole === "CompanyUser" ? userInfo.userId : customerUsersData]);

  const handleDeleteProduct = async (Id) => {
    try {
      const { isConfirmed } = await Swal.fire({
        title: "Are you sure?",
        text: "This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      });

      if (isConfirmed) {
        const response = await DeleteProductByIdAPI(Id);
        if (response) {
          await handleGetCardByUserId();
          Swal.fire(
            "Deleted!",
            "Revenue has been deleted successfully!",
            "success"
          );
        } else {
          Swal.fire("Error!", "Failed to delete revenue.", "error");
        }
      }
    } catch (error) {
      toast.error("Error submitting form:", error);
    }
  };

  const saveproductDetails = useMemo(() => {
    return cartData.map((item) => ({
      id: item.id,
      productName: item.productName,
      productPrice: item.productPrice,
      typeofSupply: item.typeofSupply,
      quantity: item.quantity,
      userId: item?.userId,
    }));
  }, [cartData]);

  useEffect(() => {
    if (saveproductDetails.length > 0) {
      const firstProduct = saveproductDetails[0];
      setItemDtails((prevDetails) => ({
        ...prevDetails,
        productName: firstProduct?.productName,
        productPrice: firstProduct.productPrice,
        typeofSupply: firstProduct.typeofSupply,
        quantity: firstProduct.quantity,
        userId: firstProduct?.userId,
        id: firstProduct.id,
      }));
    }
  }, [saveproductDetails]);

  const handleUpdateProduct = async (details) => {
    console.log("object", details);
    const productDetails = {
      id: details?.id,
      productName: details?.productName ? details?.productName : "string",
      productPrice: details?.productPrice,
      typeofSupply: selectCategory || details?.typeofSupply,
      quantity: details?.quantity,
      userId: details?.userId,
    };
    try {
      const response = await UpdateProductByIdAPI(productDetails);

      dispatch(setOpenTypeofSupply(false));

      if (response.status) {
        handleGetCardByUserId();
      }
    } catch (error) {
      console.log("error while update the product", error);
    }
  };
  const handleDecrementItems = (item) => {
    if (item.quantity > 1) {
      const updatedItem = { ...item, quantity: item.quantity - 1 };
      handleUpdateProduct(updatedItem);
    }
  };
  const handleIncrementItems = (item) => {
    const updatedItem = { ...item, quantity: item.quantity + 1 };
    handleUpdateProduct(updatedItem);
  };
  // const handleDecrementItems = (item) => {
  //   if (item.quantity > 1) {
  //     handleUpdateProduct(item);
  //   }
  // };
  // const handleIncrementItems = (item) => {
  //   console.log(item,"datdat");
  //   handleUpdateProduct(item);
  // };
  const openTypeofSupply = useSelector(
    (state) => state.jobSlice.openTypeofSupply
  );

  const handleTypeofSupplyId = (item) => {
    dispatch(setOpenTypeofSupply(true));
  };
  useEffect(() => {
    if (onChangeFunc) {
      handleUpdateProduct(itemDtails);
      console.log("itemDtailsdd", itemDtails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCategory, onChangeFunc]);
  console.log(cartData, "cartData");
  return (
    <>
      {" "}
      <div>
        {" "}
        <button
          className="btn btn-success mb-2"
          onClick={() => window.history.back()}
        >
          Go Back
        </button>
      </div>
      <div className="custom_form1 mb-4 position-relative">
        {isLoading ? (
          <p>Loading...</p>
        ) : cartData.length === 0 ? (
          <p>No items in the cart.</p>
        ) : (
          <div>
            <div>
              <Table striped className="table product_sec">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Total Price</th>
                    <th>Action</th>
                    <th>typeofSupply</th>
                  </tr>
                </thead>
                <tbody>
                  {cartData.map((item) => (
                    <tr key={item.id}>
                      <td className="custom_img">{item.productName}</td>
                      <td> ${item.productPrice}</td>
                      <td>
                        {" "}
                        <div className="quantity">
                          <button onClick={() => handleDecrementItems(item)}>
                            -
                          </button>
                          {item.quantity}
                          {console.log("item.quantity", item.quantity)}
                          <button onClick={() => handleIncrementItems(item)}>
                            +
                          </button>
                        </div>
                      </td>
                      <td>${item.productPrice * item.quantity}</td>
                      <td>
                        <button
                          className="dele_btn"
                          onClick={() => handleDeleteProduct(item.id)}
                        >
                          {" "}
                          <FontAwesomeIcon icon={faTrash} className="" />
                        </button>
                      </td>
                      <td>
                        <div onClick={() => handleTypeofSupplyId(item)}>
                          {item.typeofSupply}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </div>
      <div className="custom_form1 mb-4 position-relative">
        <Table striped className="summery_sec">
          <tbody>
            <tr>
              <td>
                {" "}
                <label for="promo">Code promo</label>
              </td>
              <td>
                <input
                  type="text"
                  id="promo"
                  className="w-100 border-white form-control h-50 mb-0"
                />
              </td>
              <td>
                <Button className="btn-app">Apply</Button>
              </td>
            </tr>
            <tr>
              <td>Order Summary</td>
              <td colSpan={2} className="text-end">
                $2700
              </td>
            </tr>
            <tr>
              <td>shipping</td>

              <td colSpan={2} className="text-end">
                Free
              </td>
            </tr>
            <tr>
              <td>Total amount</td>
              <td colSpan={2} className="text-end">
                {productDetails?.totalPrice}
              </td>
            </tr>
          </tbody>
        </Table>
        {openTypeofSupply ? <SelectPopup /> : ""}
      </div>
    </>
  );
};
